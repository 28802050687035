var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.rubric)?_c('div',[_c('InstrumentHeader',{attrs:{"Instrument":_vm.rubric,"TestForm":false,"allows_crud":false}})],1):_vm._e(),(_vm.rubric)?_c('div',{staticClass:"evaluate-instrument-container"},[[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"3"}}),_c('th',{attrs:{"colspan":_vm.rubricAchievementList.length > 0
                  ? _vm.rubricAchievementList.length
                  : 1}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Niveles de Logro" ))+" ")]),(_vm.allows_crud)?_c('th'):_vm._e(),(_vm.selectedStudent)?_c('th',{attrs:{"colspan":"3"}},[_vm._v("Notas")]):_vm._e()]),_c('tr',[_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" ))+" ")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Dimensiones" ))+" ")]),_c('th',[_vm._v("Ponderación")]),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('th',{key:rubric_achievement.id},[_vm._v(" "+_vm._s(rubric_achievement.text)+" "),_c('br'),_vm._v(" "+_vm._s(rubric_achievement.score)+" puntos ")])}),(_vm.rubricAchievementList.length == 0)?_c('th',[_vm._v(" No tiene "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Niveles de Logro" ))+" ")]):_vm._e(),(_vm.allows_crud)?_c('th',{staticStyle:{"width":"5%"}}):_vm._e(),(_vm.selectedStudent)?_c('th',[_vm._v("Puntaje")]):_vm._e(),(_vm.selectedStudent)?_c('th',[_vm._v("Factor")]):_vm._e(),(_vm.selectedStudent)?_c('th',[_vm._v("Nota")]):_vm._e()],2)]),_c('tbody',[_vm._l((_vm.rubricInstanceList),function(rubric_instance,index){return [_c('tr',{key:`${rubric_instance.id}
          `},[_c('td',[(
                    rubric_instance.evaluation_criterias_macro.length > 0 ||
                    rubric_instance.evaluation_criterias_micro.length > 0
                  )?[_c('EvaluationCriterias',{attrs:{"key_id":rubric_instance.id,"evaluation_id":_vm.rubric.evaluation,"ECMacro":rubric_instance.evaluation_criterias_macro,"ECMicro":rubric_instance.evaluation_criterias_micro,"Large":18}})]:[_c('span',[_vm._v("Esta "+_vm._s(_vm.$getVisibleNames("manual.dimension", false, "Dimensión"))+" no tiene asociado un "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", false, "Criterio de evaluación" ))+".")])]],2),_c('td',[_vm._v(_vm._s(rubric_instance.full_sentence))]),_c('td',[_vm._v(_vm._s(rubric_instance.weighing)+"%")]),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('td',{key:rubric_achievement.id},[_c('DescriptorCell',{attrs:{"observation_instance_id":rubric_instance.id,"observation_achievement_id":rubric_achievement.id,"allows_crud":_vm.allows_crud,"is_evaluating":true,"period_id":_vm.period_id,"evaluating_user":_vm.selectedStudent},on:{"updateAutomatedScore":_vm.updateAutomatedScore}})],1)}),(_vm.rubricAchievementList.length == 0)?_c('td'):_vm._e(),(_vm.selectedStudent && !_vm.updating)?_c('td',[_vm._v(" "+_vm._s(_vm.scores[rubric_instance.id] ? _vm.scores[rubric_instance.id].score : null)+" ")]):_vm._e(),(_vm.selectedStudent && !_vm.updating)?_c('td',[_vm._v(" "+_vm._s(_vm.scores[rubric_instance.id] ? _vm.scores[rubric_instance.id].factor : null)+" ")]):_vm._e(),(_vm.selectedStudent && !_vm.updating && index == 0)?_c('td',{attrs:{"rowspan":_vm.rubricInstanceList.length}},[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.scores["final_score"]))+" ")]):_vm._e()],2)]})],2),_c('tfoot',[_c('tr',[_vm._m(0),_c('td',{style:({ color: _vm.totalWeighing != 100 ? 'red' : '' }),attrs:{"title":_vm.totalWeighing != 100
                  ? 'La ponderación total debe ser igual a 100.'
                  : ''}},[_c('strong',[_vm._v(_vm._s(_vm.totalWeighing)+"%")])]),_vm._l((_vm.rubricAchievementList),function(rubric_achievement){return _c('td',{key:rubric_achievement.id})}),(_vm.rubricAchievementList.length == 0)?_c('td'):_vm._e(),(_vm.allows_crud)?_c('td'):_vm._e(),(_vm.selectedStudent && !_vm.updating)?_c('td',[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.scores["total_score"]))+" ")]):_vm._e(),(_vm.selectedStudent && !_vm.updating)?_c('td',[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.scores["total_factor"]))+" ")]):_vm._e(),(_vm.selectedStudent && !_vm.updating)?_c('td'):_vm._e()],2)])])],_c('div',{staticClass:"question-test-score pt-2"},[_c('strong',{staticClass:"border-bottom pb-1"},[_vm._v("Evaluar "+_vm._s(_vm.rubric.title))]),_c('div',{staticClass:"border-bottom mt-2 pb-1 ml-3 text-justify"},[_vm._v(" Archivos adjuntos de "+_vm._s(_vm.selectedStudent ? _vm.selectedStudent.first_name : "")+": "),(_vm.rubric_score && _vm.rubric_score.attachments.length > 0)?[_c('FileContainer',{attrs:{"Files":_vm.rubric_score.attachments,"allows_crud":false}})]:[_vm._v("N/A")]],2),_c('div',{staticClass:"mt-3"},[_c('b-form-group',{staticClass:"mb-0 mx-3 py-1 text-left border-bottom",attrs:{"label":"Puntaje propuesto:","label-cols":"4","label-cols-sm":"8"}},[_c('span',{staticClass:"automated-score"},[_vm._v(_vm._s(_vm._f("Round")(_vm.rubric_score ? _vm.rubric_score.automated_score >= 0 ? _vm.rubric_score.automated_score : 0 : 0))+" / "+_vm._s(_vm.rubric.score))])]),(_vm.institution)?_c('b-form-group',{staticClass:"mb-0 mx-3 py-1 text-left border-bottom",attrs:{"label":"Puntaje asignado:","label-cols":"4","label-cols-sm":"8"}},[(
              _vm.institution.actual_period != null &&
              _vm.institution.actual_period.id == _vm.period_id
            )?[_c('b-form-input',{attrs:{"type":"number","size":"sm","disabled":!_vm.student_id || isNaN(_vm.manual_score.id)},model:{value:(_vm.manual_score.score),callback:function ($$v) {_vm.$set(_vm.manual_score, "score", _vm._n($$v))},expression:"manual_score.score"}})]:[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.manual_score.score))+" ")]],2):_vm._e(),_c('b-form-group',{staticClass:"mb-0 mx-3 py-1 text-left border-bottom",attrs:{"label":"Nota Final:","label-cols":"4","label-cols-sm":"8"}},[_vm._v(" "+_vm._s(_vm.getInstrumentFinalScore())+" ")]),(_vm.institution)?_c('div',{staticClass:"feedback-section mx-2 mt-2 py-2"},[_c('div',{staticClass:"text-left ml-2 mb-3"},[_vm._v("Retroalimentación:")]),_c('b-form-textarea',{attrs:{"size":"sm","disabled":!_vm.student_id ||
              isNaN(_vm.manual_score.id) ||
              (_vm.institution.actual_period != null &&
                _vm.institution.actual_period.id != _vm.period_id),"rows":"6","max-rows":"6"},model:{value:(_vm.manual_score.feedback),callback:function ($$v) {_vm.$set(_vm.manual_score, "feedback", $$v)},expression:"manual_score.feedback"}})],1):_vm._e(),(_vm.student_id && _vm.institution)?_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"btn-save mt-2 ml-2 px-5",attrs:{"disabled":!_vm.student_id ||
              isNaN(_vm.manual_score.id) ||
              (_vm.institution.actual_period != null &&
                _vm.institution.actual_period.id != _vm.period_id),"size":"sm"},on:{"click":function($event){return _vm.patchRubricScore({
                score: _vm.manual_score.score,
                feedback: _vm.manual_score.feedback,
                is_graded: true,
              })}}},[_vm._v(" Guardar ")]),(!_vm.manual_score.publish)?_c('b-button',{staticClass:"btn-publish-grading mt-2 mr-2 px-5",attrs:{"disabled":!_vm.student_id ||
              isNaN(_vm.manual_score.id) ||
              (_vm.institution.actual_period != null &&
                _vm.institution.actual_period.id != _vm.period_id),"size":"sm","variant":"none"},on:{"click":function($event){return _vm.patchRubricScore({
                score: _vm.manual_score.score,
                feedback: _vm.manual_score.feedback,
                is_graded: true,
                publish: true,
              })}}},[_vm._v(" Publicar ")]):_vm._e()],1):_vm._e()],1)])],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v("Totales")])])
}]

export { render, staticRenderFns }