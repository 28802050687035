<template>
  <div>
    <div v-if="rubric">
      <InstrumentHeader
        :Instrument="rubric"
        :TestForm="false"
        :allows_crud="false"
      ></InstrumentHeader>
    </div>
    <div v-if="rubric" class="evaluate-instrument-container">
      <template class="test-container">
        <table>
          <thead>
            <tr>
              <th colspan="3"></th>
              <th
                :colspan="
                  rubricAchievementList.length > 0
                    ? rubricAchievementList.length
                    : 1
                "
              >
                {{
                  $getVisibleNames(
                    "evaluations.observationachievement",
                    true,
                    "Niveles de Logro"
                  )
                }}
              </th>
              <th v-if="allows_crud"></th>
              <th v-if="selectedStudent" colspan="3">Notas</th>
            </tr>
            <tr>
              <th style="width: 15%">
                {{
                  $getVisibleNames(
                    "manual.criterio_de_evaluacion",
                    true,
                    "Criterios de Evaluación"
                  )
                }}
                <!-- Indicador de Logro -->
              </th>
              <th style="width: 15%">
                {{
                  $getVisibleNames(
                    "evaluations.observationachievement",
                    true,
                    "Dimensiones"
                  )
                }}
              </th>
              <th>Ponderación</th>
              <th
                v-for="rubric_achievement in rubricAchievementList"
                :key="rubric_achievement.id"
              >
                {{ rubric_achievement.text }}
                <br />
                {{ rubric_achievement.score }} puntos
              </th>
              <th v-if="rubricAchievementList.length == 0">
                No tiene
                {{
                  $getVisibleNames(
                    "evaluations.observationachievement",
                    true,
                    "Niveles de Logro"
                  )
                }}
              </th>
              <th style="width: 5%" v-if="allows_crud"></th>
              <th v-if="selectedStudent">Puntaje</th>
              <th v-if="selectedStudent">Factor</th>
              <th v-if="selectedStudent">Nota</th>
            </tr>
          </thead>
          <tbody>
            <!-- <template v-for="(criteria, rubric_index) in evaluationCriteriaList"> -->
            <template v-for="(rubric_instance, index) in rubricInstanceList">
              <!-- v-for="rubric_instances in [
                rubricInstanceList.filter(
                  (x) => x.evaluation_criteria == criteria.id
                ),
              ]" -->
              <!-- <template v-for="(rubric_instance, index) in rubric_instances"> -->
              <tr
                :key="`${rubric_instance.id}
            `"
              >
                <!-- -${index} -->
                <td>
                  <template
                    v-if="
                      rubric_instance.evaluation_criterias_macro.length > 0 ||
                      rubric_instance.evaluation_criterias_micro.length > 0
                    "
                  >
                    <EvaluationCriterias
                      :key_id="rubric_instance.id"
                      :evaluation_id="rubric.evaluation"
                      :ECMacro="rubric_instance.evaluation_criterias_macro"
                      :ECMicro="rubric_instance.evaluation_criterias_micro"
                      :Large="18"
                    ></EvaluationCriterias>
                  </template>
                  <template v-else>
                    <span
                      >Esta
                      {{
                        $getVisibleNames("manual.dimension", false, "Dimensión")
                      }}
                      no tiene asociado un
                      {{
                        $getVisibleNames(
                          "manual.criterio_de_evaluacion",
                          false,
                          "Criterio de evaluación"
                        )
                      }}.</span
                    >
                  </template>
                  <!-- <td v-if="index == 0" :rowspan="rubric_instances.length"> -->
                  <!-- <SentenceContainer
                    :Sentence="criteria"
                    class="sentence-rubric"
                  ></SentenceContainer> -->
                </td>
                <td>{{ rubric_instance.full_sentence }}</td>
                <td>{{ rubric_instance.weighing }}%</td>
                <td
                  v-for="rubric_achievement in rubricAchievementList"
                  :key="rubric_achievement.id"
                >
                  <DescriptorCell
                    :observation_instance_id="rubric_instance.id"
                    :observation_achievement_id="rubric_achievement.id"
                    :allows_crud="allows_crud"
                    :is_evaluating="true"
                    :period_id="period_id"
                    @updateAutomatedScore="updateAutomatedScore"
                    :evaluating_user="selectedStudent"
                  ></DescriptorCell>
                </td>
                <td v-if="rubricAchievementList.length == 0"></td>
                <td v-if="selectedStudent && !updating">
                  {{
                    scores[rubric_instance.id]
                      ? scores[rubric_instance.id].score
                      : null
                  }}
                </td>
                <td v-if="selectedStudent && !updating">
                  {{
                    scores[rubric_instance.id]
                      ? scores[rubric_instance.id].factor
                      : null
                  }}
                </td>
                <td
                  v-if="selectedStudent && !updating && index == 0"
                  :rowspan="rubricInstanceList.length"
                >
                  <!-- rubric_index == 0 && -->
                  {{ scores["final_score"] | Round }}
                </td>
              </tr>
              <!-- </template> -->
            </template>
            <!-- </template> -->
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                <strong>Totales</strong>
              </td>
              <td
                :title="
                  totalWeighing != 100
                    ? 'La ponderación total debe ser igual a 100.'
                    : ''
                "
                v-bind:style="{ color: totalWeighing != 100 ? 'red' : '' }"
              >
                <strong>{{ totalWeighing }}%</strong>
              </td>
              <td
                v-for="rubric_achievement in rubricAchievementList"
                :key="rubric_achievement.id"
              ></td>
              <td v-if="rubricAchievementList.length == 0"></td>
              <td v-if="allows_crud"></td>
              <td v-if="selectedStudent && !updating">
                {{ scores["total_score"] | Round }}
              </td>
              <td v-if="selectedStudent && !updating">
                {{ scores["total_factor"] | Round }}
              </td>
              <td v-if="selectedStudent && !updating"></td>
            </tr>
          </tfoot>
        </table>
      </template>
      <div class="question-test-score pt-2">
        <strong class="border-bottom pb-1">Evaluar {{ rubric.title }}</strong>
        <div class="border-bottom mt-2 pb-1 ml-3 text-justify">
          Archivos adjuntos de
          {{ selectedStudent ? selectedStudent.first_name : "" }}:

          <template v-if="rubric_score && rubric_score.attachments.length > 0">
            <FileContainer
              :Files="rubric_score.attachments"
              :allows_crud="false"
            ></FileContainer>
          </template>
          <template v-else>N/A</template>
        </div>
        <div class="mt-3">
          <b-form-group
            label="Puntaje propuesto:"
            label-cols="4"
            label-cols-sm="8"
            class="mb-0 mx-3 py-1 text-left border-bottom"
          >
            <span class="automated-score"
              >{{
                rubric_score
                  ? rubric_score.automated_score >= 0
                    ? rubric_score.automated_score
                    : 0
                  : 0 | Round
              }}
              / {{ rubric.score }}</span
            >
          </b-form-group>
          <b-form-group
            label="Puntaje asignado:"
            label-cols="4"
            label-cols-sm="8"
            class="mb-0 mx-3 py-1 text-left border-bottom"
            v-if="institution"
          >
            <template
              v-if="
                institution.actual_period != null &&
                institution.actual_period.id == period_id
              "
            >
              <b-form-input
                type="number"
                size="sm"
                :disabled="!student_id || isNaN(manual_score.id)"
                v-model.number="manual_score.score"
              ></b-form-input>
            </template>
            <template v-else>
              {{ manual_score.score | Round }}
            </template>
          </b-form-group>

          <b-form-group
            label="Nota Final:"
            label-cols="4"
            label-cols-sm="8"
            class="mb-0 mx-3 py-1 text-left border-bottom"
          >
            {{ getInstrumentFinalScore() }}
          </b-form-group>
          <div class="feedback-section mx-2 mt-2 py-2" v-if="institution">
            <div class="text-left ml-2 mb-3">Retroalimentación:</div>
            <b-form-textarea
              size="sm"
              :disabled="
                !student_id ||
                isNaN(manual_score.id) ||
                (institution.actual_period != null &&
                  institution.actual_period.id != period_id)
              "
              v-model="manual_score.feedback"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </div>
          <div v-if="student_id && institution" class="d-flex">
            <b-button
              class="btn-save mt-2 ml-2 px-5"
              :disabled="
                !student_id ||
                isNaN(manual_score.id) ||
                (institution.actual_period != null &&
                  institution.actual_period.id != period_id)
              "
              size="sm"
              @click="
                patchRubricScore({
                  score: manual_score.score,
                  feedback: manual_score.feedback,
                  is_graded: true,
                })
              "
            >
              Guardar
            </b-button>
            <b-button
              class="btn-publish-grading mt-2 mr-2 px-5"
              v-if="!manual_score.publish"
              :disabled="
                !student_id ||
                isNaN(manual_score.id) ||
                (institution.actual_period != null &&
                  institution.actual_period.id != period_id)
              "
              size="sm"
              variant="none"
              @click="
                patchRubricScore({
                  score: manual_score.score,
                  feedback: manual_score.feedback,
                  is_graded: true,
                  publish: true,
                })
              "
            >
              Publicar
            </b-button>
          </div>
        </div>
      </div>
      <!-- Modals -->
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast, generateUniqueId } from "@/utils/utils";

export default {
  name: "EvaluateRubric",
  components: {
    DescriptorCell: () => import("./DescriptorCell"),
    EvaluationCriterias: () =>
      import("@/components/evaluations/EvaluationCriterias.vue"),
    FileContainer: () => import("@/components/reusable/FileContainer"),
    // SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    InstrumentHeader: () => import("@/components/evaluations/InstrumentHeader"),
  },
  props: {
    observation_instrument_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    is_evaluating: {
      type: Boolean,
      default: true,
    },
    student_id: {
      type: Number,
    },
    period_id: {
      type: Number,
    },
  },
  data() {
    return {
      scores: {},
      updating: false,
      manual_score: {
        id: generateUniqueId(),
        score: null,
        feedback: "",
        is_graded: true,
        publish: true,
      },
      rubric_score: null,
    };
  },
  computed: {
    ...mapGetters({
      rubrics: names.OBSERVATION_INSTRUMENTS,
      rubricAchievements: names.OBSERVATION_ACHIEVEMENTS,
      rubricInstances: names.OBSERVATION_INSTANCES,
      rubricDescriptors: names.OBSERVATION_DESCRIPTORS,
      rubricScores: names.OBSERVATION_SCORES,
      institution: "getInstitution",
      evaluations: names.EVALUATIONS,
      studyUnits: names.STUDY_UNITS,
      sections: names.SECTIONS,
      users: names.USERS,
    }),
    rubric() {
      return this.rubrics.find((x) => x.id == this.observation_instrument_id);
    },
    rubricAchievementList() {
      return this.rubricAchievements
        .filter(
          (x) => x.observation_instrument == this.observation_instrument_id
        )
        .sort(function (a, b) {
          return a.score > b.score ? -1 : 1;
        });
    },
    rubricInstanceList() {
      return this.rubricInstances.filter(
        (x) => x.observation_instrument == this.observation_instrument_id
      );
    },
    rubricDescriptorList() {
      let list = [];
      this.rubricInstanceList.forEach((x) => {
        list.push(x.id);
      });
      return this.rubricDescriptors.filter((x) =>
        x.observation_instance.includes(list)
      );
    },
    selectedStudent() {
      if (!this.student_id) return null;
      return this.users.find((x) => x.id == this.student_id);
    },
    totalWeighing() {
      let weighing = 0;
      this.rubricInstanceList.forEach((element) => {
        weighing += Number(element.weighing);
      });
      return weighing;
    },
    evaluation() {
      if (!this.rubric) return null;
      return this.evaluations.find((x) => x.id == this.rubric.evaluation);
    },
    section() {
      if (!this.evaluation) return null;
      return this.sections.find((x) => x.id == this.evaluation.section);
    },
    studyUnit() {
      if (!this.evaluation) return null;
      return this.studyUnits.find((x) => x.id == this.evaluation.study_unit);
    },
    // evaluationCriteriaList() {
    //   if (!this.studyUnit) return [];
    //   return this.evaluationCriterias.filter(
    //     (x) => x.study_unit == this.studyUnit.id
    //   );
    // },
    filteredRubricScores() {
      let list = [];
      if (!this.student_id) return [];
      this.rubricInstanceList.forEach((instance) => {
        const scores = this.rubricScores.filter(
          (x) =>
            x.student == this.student_id &&
            x.observation_instance == instance.id
        );
        list.push(...scores);
      });
      return list;
    },
    // maxInstrumentScore() {
    //   let score = 0;
    //   this.rubricAchievementList.forEach((achievement) => {
    //     if (achievement.score > score) score = achievement.score;
    //   });
    //   return score * this.filteredRubricScores.length;
    // },
  },
  methods: {
    // slotSelectedStudent(student_id) {
    //   this.student_id = student_id;
    // },
    fetchObservationInstrumentScore() {
      if (this.student_id) {
        this.$restful
          .Get(
            `/evaluation/observation-instrument-score/?observation_instrument=${this.observation_instrument_id}&student=${this.student_id}`
          )
          .then((response) => {
            this.rubric_score = response.find(
              (x) =>
                x.observation_instrument == this.observation_instrument_id &&
                x.student == this.student_id
            );
            if (response.length > 0)
              if (response[0].score_and_feedback != null)
                this.fetchManualScores(response[0].score_and_feedback.id);
          });
      }
    },
    updateAutomatedScore() {
      this.fetchObservationInstrumentScore();
    },
    fetchManualScores(manual_score_id) {
      if (this.student_id) {
        this.$restful
          .Get(`/evaluation/manual-score/${manual_score_id}/`)
          .then((response) => {
            this.manual_score = response;
          });
      }
    },
    patchRubricScore(item) {
      this.$restful
        .Patch(`/evaluation/manual-score/${this.manual_score.id}/`, item)
        .then((response) => {
          this.manual_score = response;
          toast("Puntaje de Instrumento actualizado.");
        });
    },
    getInstrumentFinalScore() {
      if (this.institution) {
        const appr_grade = this.institution.appr_grade;
        const exigence_percentage = this.institution.exigence_percentage / 100;
        const min_grade = this.institution.min_grade;
        const max_grade = this.institution.max_grade;
        const max_score = this.rubric.score;
        const automated_score =
          this.rubric_score &&
          this.rubric_score.automated_score &&
          this.rubric_score.automated_score > 0 &&
          this.rubric_score.automated_score != null
            ? this.rubric_score.automated_score
            : 0;
        const manual_score = this.manual_score.score;
        let tem_grade = min_grade;
        let obtained_score = 0;
        if (manual_score != null) {
          obtained_score = manual_score;
        } else {
          obtained_score = automated_score;
        }
        if (obtained_score < exigence_percentage * max_score) {
          tem_grade =
            ((appr_grade - min_grade) * obtained_score) /
              (exigence_percentage * max_score) +
            min_grade;
        } else {
          tem_grade =
            ((max_grade - appr_grade) *
              (obtained_score - exigence_percentage * max_score)) /
              (max_score * (1 - exigence_percentage)) +
            appr_grade;
        }
        if (tem_grade > max_grade) return parseFloat(max_grade.toFixed(1));
        else if (tem_grade < min_grade) return parseFloat(min_grade.toFixed(1));
        else return parseFloat(tem_grade.toFixed(1));
      }
    },
    deleteRubric() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Rúbrica?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_OBSERVATION_INSTRUMENT, this.rubric.id)
            .then(() => {
              this.$emit("deleted", this.rubric);
              toast("Rúbrica eliminada.");
            });
        }
      });
    },
    updateScores() {
      this.updating = true;
      let institution_max_score = 7;
      let institution_min_score = 1;
      try {
        institution_max_score = this.$store.getters.getinstitution.max_grade;
        institution_min_score = this.$store.getters.getinstitution.min_grade;
      } catch {
        institution_max_score = 7;
        institution_min_score = 1;
      }
      let total_score = 0;
      let total_factor = 0;
      this.rubricInstanceList.forEach((instance) => {
        let score = 0;
        let factor = 0;
        const rubric_score = this.filteredRubricScores.find(
          (x) => x.observation_instance == instance.id
        );
        if (rubric_score) {
          const achievement = this.rubricAchievements.find(
            (x) => x.id == rubric_score.observation_achievement
          );
          score = achievement.score;
          factor = (achievement.score * instance.weighing) / 100;
          total_score += score;
          total_factor += factor;
        }
        this.scores[instance.id] = {
          score: score,
          factor: factor,
        };
      });
      this.scores["total_score"] = total_score;
      this.scores["total_factor"] = total_factor;
      // const final_score =
      //   (institution_max_score * total_score) / this.maxInstrumentScore;
      if (total_factor > institution_max_score)
        this.scores["final_score"] = institution_max_score;
      if (total_factor < institution_min_score)
        this.scores["final_score"] = institution_min_score;
      this.scores["final_score"] = total_factor;
      this.updating = false;
    },
  },
  watch: {
    filteredRubricScores() {
      this.updateScores();
    },
    student_id() {
      this.manual_score = {
        id: generateUniqueId(),
        score: null,
        feedback: "",
      };
      this.fetchObservationInstrumentScore();
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.fetchObservationInstrumentScore();
    this.$store.dispatch(
      names.FETCH_SCORE_BY_INSTRUMENT,
      this.observation_instrument_id
    );
    this.$store.dispatch(
      names.FETCH_OBSERVATION_INSTANCES,
      this.observation_instrument_id
    );
    this.$store
      .dispatch(
        names.FETCH_OBSERVATION_INSTRUMENT,
        this.observation_instrument_id
      )
      .then((rubric) => {
        this.$store.dispatch(names.FETCH_EVALUATION, rubric.evaluation);
        rubric.attachments.forEach((element) => {
          this.$store.dispatch(names.FETCH_FILE, element);
        });
        this.$store
          .dispatch(names.FETCH_SECTION_EVALUATIONS, rubric.evaluation)
          .then((response) => {
            response.forEach((section_evaluation) => {
              section_evaluation.evaluation_criterias_micro.forEach(
                (ra_micro_id) => {
                  this.$store.dispatch(
                    names.FETCH_EVALUATION_CRITERIA,
                    ra_micro_id
                  );
                }
              );
              section_evaluation.evaluation_criterias_macro.forEach(
                (ra_macro_id) => {
                  this.$store.dispatch(
                    names.FETCH_EVALUATION_CRITERIA_MACRO,
                    ra_macro_id
                  );
                }
              );
            });
          });
      });
  },
  mounted() {},
};
</script>

<style scoped>
table {
  width: 100%;
  font-size: var(--secondary-font-size);
}
.evaluate-instrument-container {
  display: flex;
}
.test-container {
  background-color: var(--primary-color);
  border-radius: 5px;
  width: 75%;
}
td,
th {
  border: 1px solid black;
}
th {
  font-size: var(--secondary-font-size);
  font-weight: bold;
  background-color: var(--primary-color);
  color: white;
}
tfoot {
  background-color: var(--secondary-color);
  font-weight: bold;
}
.row-div {
  display: flex;
  flex-direction: row;
}
.delete-div {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
}
.edit-div {
  display: flex;
  flex-direction: row;
  width: 50%;
}
.question-test-score {
  background: #dfecf4;
  width: 25%;
}
.automated-score {
  font-weight: bold;
}
.sentence-rubric {
  margin-bottom: 0;
  padding-top: 15px !important;
}
.btn-save,
.btn-publish-grading {
  width: 46%;
}
.btn-publish-grading {
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
  transition: all 0.3s;
  margin-left: auto;
}
.btn-publish-grading:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
}
</style>